import React from 'react';

export default function Certificate() {
  return (
    <section id="certificates" className="">
      <h1 className="spa-header main-font">CERTIFICATE</h1>
      <div>
        <div className="certificate">
          <div className="main-font title-font">
            HACCP certificate warehousing and distribution
          </div>
          <img
            src="/images/certificates/HACCP-SPA-QLD-exp-14082025.png"
            alt="certificate"
            width="100%"
          />
        </div>
        <div className="certificate">
          <div className="main-font title-font">
            BRC certificate for our manufacturing plant
          </div>
          <img
            src="/images/certificates/BRC-Certificate.png"
            alt="certificate"
            width="100%"
          />
        </div>
        <div className="certificate">
          <div className="main-font title-font">
            Food grade test reports complying to European and US FDA standards
          </div>
          <p className="second-font paragraph-font">
            Please contact our team to obtain a copy.
          </p>
        </div>
      </div>
    </section>
  );
}
