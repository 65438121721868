import React, { useEffect, useMemo } from 'react';
import { Product, PRODUCTS } from '../../ProductList';
import { Link, useLocation, useParams } from 'react-router-dom';
import { SpaContainer } from '../SpaContainer';

export type CategoryType =
  | 'fresh-produce-clamshells'
  | 'fresh-produce-trays'
  | 'meal-trays'
  | 'open-cell-and-meat-trays'
  | 'cups-and-tubs'
  | 'bakery-and-salad';

const categoryButton: {
  label: string;
  params: CategoryType;
}[] = [
  {
    label: 'FRESH PRODUCE CLAMSHELLS',
    params: 'fresh-produce-clamshells',
  },
  {
    label: 'FRESH PRODUCE TRAYS',
    params: 'fresh-produce-trays',
  },
  {
    label: 'MEAL TRAYS AND LIDS',
    params: 'meal-trays',
  },
  {
    label: 'OPEN CELL TRAYS / MEAT TRAYS',
    params: 'open-cell-and-meat-trays',
  },
  {
    label: 'CUPS AND TUBS',
    params: 'cups-and-tubs',
  },
];

export function loader({ params }: any) {
  const { category } = params;
  return { category };
}

export const formatCategoryText = (category: CategoryType) => {
  const _category = category.replaceAll('-', ' ');
  return _category[0].toUpperCase() + _category.slice(1);
};

const getType = (type: string | undefined) => {
  return type ? type + '/' : '';
};

export const generateInfoPath = (category: CategoryType, p: Product) => {
  return `/images/product/${category}/${getType(p.subtype)}${p.productCode}/${
    p.productCode
  }`;
};

export default function AllProductPage() {
  const location = useLocation();
  const params = useParams();

  const { hash } = useLocation();
  console.log(location, params, hash);
  const category = useMemo(() => {
    return (params.category as CategoryType) ?? 'fresh-produce-clamshells';
  }, [params]);

  const selectedCategoryList = useMemo(() => {
    return PRODUCTS[category];
  }, [category]);

  const selectedSplitCategoryList = useMemo(() => {
    const map = new Map<string, Product[]>();
    map.set(category, []);
    selectedCategoryList.forEach((p) => {
      if (p.subtype) {
        if (!map.has(p.subtype)) {
          map.set(p.subtype, []);
        }
        map.get(p.subtype)?.push(p);
      } else {
        map.get(category)?.push(p);
      }
    });
    return map;
  }, [category, selectedCategoryList]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  });

  const getActiveCssStr = (selected: CategoryType, cat: CategoryType) => {
    if (selected === cat.toLowerCase()) return 'active';
    else return '';
  };

  return (
    <SpaContainer>
      <h1 className="spa-header product-header main-font">
        {formatCategoryText(category)}
      </h1>
      {/* Category Buttons */}
      <div className="product-categories">
        {categoryButton.map((cat) => {
          return (
            <button
              className={`main-font ${getActiveCssStr(category, cat.params)}`}
              key={cat.params}
            >
              <Link to={`/product/${cat.params}`}>{cat.label}</Link>
            </button>
          );
        })}
      </div>
      {/* All Product Photos */}
      <div id="selected-product-list">
        {/* Main type products */}
        {selectedSplitCategoryList.get(category)?.map((p) => (
          <ProductItem
            path={generateInfoPath(category, p)}
            productCode={p.productCode}
            key={p.productCode}
          />
        ))}
        {/* Subtype products */}
        {Array.from(selectedSplitCategoryList.keys())
          .filter((k) => k !== category)
          .map((k) => (
            <>
              <h5 className="main-font subtype-title" key={k}>
                {k}
              </h5>
              {selectedSplitCategoryList.get(k)?.map((p) => (
                <ProductItem
                  path={generateInfoPath(category, p)}
                  productCode={p.productCode}
                  key={p.productCode}
                />
              ))}
            </>
          ))}
      </div>
    </SpaContainer>
  );
}

interface ProductItemProps {
  productCode: string;
  path: string;
}

function ProductItem(props: ProductItemProps) {
  const { productCode, path } = props;

  return (
    <div className="product-item">
      <Link to={`${productCode}`}>
        <img src={`${path}.jpg`} alt={productCode} />
        <div className="opacity">
          <div className="main-font">{productCode}</div>
        </div>
      </Link>
    </div>
  );
}
