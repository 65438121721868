import { CategoryType } from './Component/ProductPage/AllProductPage';

export interface ProductBannersInfo {
  bannerHeader: string;
}

export const PRODUCT_BANNERS: { [key in CategoryType]: ProductBannersInfo } = {
  'fresh-produce-clamshells': {
    bannerHeader: 'Fresh produce clamshells',
  },
  'fresh-produce-trays': {
    bannerHeader: 'Fresh produce trays',
  },
  'meal-trays': {
    bannerHeader: 'Meal trays and lids',
  },
  'open-cell-and-meat-trays': {
    bannerHeader: 'Open cell trays / Meat trays',
  },
  'cups-and-tubs': {
    bannerHeader: 'Cups and tubs',
  },
  'bakery-and-salad': {
    bannerHeader: 'Bakery and salad',
  },
};
