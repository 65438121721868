import React, { useEffect, useMemo, useState } from 'react';
import {
  CategoryType,
  formatCategoryText,
  generateInfoPath,
} from './AllProductPage';
import { useParams } from 'react-router-dom';
import { PRODUCTS, Product } from '../../ProductList';
import { SpaContainer } from '../SpaContainer';

export default function ProductItemPage() {
  const params = useParams();
  const category = params.category! as CategoryType;
  const productCode = params.productCode!;

  const [isLargeImgOpen, setIsLargeImgOpen] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  });

  const details: Product = useMemo(
    () => PRODUCTS[category].find((item) => item.productCode === productCode)!,
    [category, productCode]
  );
  const subtype = details.subtype ?? '';

  const infoPath = useMemo(
    () => generateInfoPath(category, details),
    [generateInfoPath, category, details]
  );

  const handleClickImage = () => {
    if (!isLargeImgOpen) {
      setIsLargeImgOpen(true);
    }
  };

  const renderLargeImg = () => {
    return (
      <div className="product-large-image">
        <div className="image-box">
          <div>
            <img
              className="large-image"
              src={`${infoPath}.jpg`}
              width="720px"
              alt="large-image"
            />
          </div>
        </div>
        <button
          className="large-image-close-btn"
          onClick={() => {
            setIsLargeImgOpen(false);
          }}
        >
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
    );
  };

  return (
    <SpaContainer>
      <div className="item-page">
        <div className="product-detail">
          <img
            src={`${infoPath}.jpg`}
            alt={productCode}
            onClick={() => handleClickImage()}
          />
          <div>
            <h1 className="spa-header product-item-header main-font">
              {productCode}
            </h1>
            <div className="detail main-font">
              <div className="detail-title">Categories:</div>
              <div className="detail-content">
                {formatCategoryText(category)}{' '}
                {details.subtype ? `/ ${subtype}` : ''}
              </div>
            </div>
            <div className="detail main-font">
              <div className="detail-title">Procudt Code:</div>
              <div className="detail-content">{productCode}</div>
            </div>
            <div className="detail main-font">
              <div className="detail-title">Description:</div>
              <div className="detail-content">{details.description ?? ''}</div>
            </div>
            <div className="detail main-font">
              <div className="detail-title">Dimensions:</div>
              <div className="detail-content">
                {details.dimensions ?? ''}{' '}
                {details.dimensions
                  ? category !== 'cups-and-tubs'
                    ? '(L x W x H)'
                    : '(D x H)'
                  : ''}
              </div>
            </div>
            <div className="detail main-font">
              <div className="detail-title">Material:</div>
              <div className="detail-content">{details.material ?? ''}</div>
            </div>
          </div>
        </div>
        <div className="specification">
          <a
            className="main-font spa-btn"
            href={`${infoPath}.pdf`}
            target="_blank"
            rel="noreferrer"
          >
            Specification
          </a>
        </div>
        {isLargeImgOpen ? renderLargeImg() : null}
      </div>
    </SpaContainer>
  );
}
