import React from 'react';

interface ContactItemProps {
  iconName: string;
  title: string;
  content: string;
  isEmail?: boolean;
}

export default function Contact() {
  return (
    <section id="contact" className="contact">
      <div className="google-map-info" data-wow-duration="900ms">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3541.2646778157537!2d153.1470882156669!3d-27.429860921578143!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b9144783681be95%3A0xafd2a9431d9f659a!2sSpecialty%20Packaging%20Aust%20Pty%20Ltd!5e0!3m2!1sen!2sau!4v1634143813985!5m2!1sen!2sau"
          width="100%"
          height="100%"
        ></iframe>
      </div>
      <div className="contact-info">
        <ContactItem
          iconName="map-pin"
          title="Visit Our Office"
          content="98 Freight St, Lytton QLD 4178"
        />
        <ContactItem
          iconName="phone"
          title="Let's Talk"
          content="Phone: 07 3299 2035"
        />
        <ContactItem
          iconName="envelope"
          title="E-mail Us"
          content="admin@specialtypackagingaust.com.au"
          isEmail={true}
        />
      </div>
      <div className="contact-description">
        <p className="second-font paragraph-font">
          Our service is just one phone call or one email away.
        </p>
        <p className="second-font paragraph-font">
          For us to best understand your requirement, please send us an email
          with your business info, the state you are based in and the packaging
          you would like to discuss with us.
        </p>
        <p className="second-font paragraph-font">
          Or simply pick up the phone to speak with our sales team.
        </p>
      </div>
    </section>
  );
}

function ContactItem(props: ContactItemProps) {
  const { iconName, title, content, isEmail } = props;
  return (
    <div id="contact-item" className="contact-item">
      <div className="icon-wrapper">
        {!isEmail ? (
          <i className={`icon-${iconName}`}></i>
        ) : (
          <a href={`mailto:${content}`} target="_blank">
            <i className={`icon-${iconName}`}></i>
          </a>
        )}
      </div>
      <div className="contact-title main-font">{title}</div>
      <div className="second-font paragraph-font">
        {!isEmail ? (
          content
        ) : (
          <a href={`mailto:${content}`} target="_blank">
            {content}
          </a>
        )}
      </div>
    </div>
  );
}
