import React from 'react';
import { goTo } from './LandingPage';

const ABOUT_TEXT_1 =
  'Specialty Packaging Aust Pty Ltd will only deal with well established manufacturers whom are Quality Credited and meet the specific requirements of our customers. In saying this SPA will work towards its own Quality and HACCP Accreditation.';
const ABOUT_TEXT_2 =
  'Our Focus is to provide superior customer service at all levels ensuring we meet all expectations for product quality and innovation. Our plastic products can be manufactured from a variety of Polymers which include PET, PP, PS, PE, ABS, PLA.';
const ABOUT_TEXT_3 =
  "Custom design with many years experience we have the ability to change improve or even suggest product design changes which can result in many benefits' to the customer. Have an Idea? Let us know we can provide translation of your ideas into reality.";
const ABOUT_TEXT_4 =
  'We are especially proud of our manufacturer’s dust free plant with state of the art technologies and equipment. This is why we are confident of our product’s quality and standard.';

export default function About() {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    goTo('solution');
  };
  return (
    <section>
      <div id="about">
        <Card index="01." title="Well established" text={ABOUT_TEXT_1} />
        <Card
          index="02."
          title="Provide superior customer service"
          text={ABOUT_TEXT_2}
        />
        <Card
          index="03."
          title="Custom design with many years experience"
          text={ABOUT_TEXT_3}
        />
        <Card
          index="04."
          title="State of the art technologies and equipment"
          text={ABOUT_TEXT_4}
        />
      </div>
      <div id="history">
        <h2>HISTORY</h2>
        <p className="second-font paragraph-font">
          Established in 2013, Specialty Packaging Aust Pty Ltd ("SPA") is a
          family-owned business based in Australia to provide the food industry
          with well performing packaging. Over a decade, we have gained trust
          from many reputable customers and have formed long term partnerships
          with our expertise in packaging and dedicated customer service. We are
          small enough to care and big enough to deliver.
        </p>
      </div>
      <div id="value">
        <h2>OUR COMPANY VALUE</h2>
        <p className="second-font paragraph-font">
          The key to our growth is always about customer satisfaction and
          innovation to fabricate high quality and suitable packaging. SPA is a
          proud member of APCO and maintains reputable certification. We are
          committed to help building circular economy.
        </p>
      </div>
      <div id="capabilities">
        <h2>OUR CAPABILITIES</h2>

        <ul>
          <li>
            <BulletTitle title="New product development" />
            <p className="second-font paragraph-font">
              We have decades of experience to assist our partners to develop
              new packaging from scratch to suit their needs. It follows the
              simple process in our Packaging Solution page{' '}
              <a href="#" onClick={handleClick}>
                <i className="fa-solid fa-arrow-up-right-from-square"></i>
              </a>
              .
            </p>
          </li>
          <li>
            <BulletTitle title="Non-stop Innovation" />
            <p className="second-font paragraph-font">
              We are always observing the market needs and working with our
              technical team to create new products that will meet the changing
              requirements. Over the years, we've worked hard and smart to have
              our own patents and registered designs. And we are dedicated to
              make products that are more recycle friendly to be a part of the
              circular economy.
            </p>
          </li>
          <li>
            <BulletTitle title="Robust supply chain" />
            <p className="second-font paragraph-font">
              We are proud of having built a consolidated supply chain in the
              aspects of raw materials, finished products and logistics to aim
              at 100% satisfaction from each of our valued customers.
            </p>
            <p className="second-font paragraph-font">
              Our dedicated customer service team is always responsive and keeps
              our customers informed about their orders, product requirements
              and all other enquiries.
            </p>
          </li>
          <li>
            <BulletTitle title="National footprint" />
            <p className="second-font paragraph-font">
              SPA supplies to most states in Australia and will strive to
              deliver to wherever our service is required. We've worked with
              local logistic companies to ensure orders are being delivered on
              time and with goods intact.
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
}

interface CardProps {
  index: string;
  title: string;
  text: string;
}

function Card(props: CardProps) {
  return (
    <div className="card main-font">
      <div className="card-title">
        <div className="index">{props.index}</div>
        <div className="title-font">{props.title}</div>
      </div>
      <div className="card-text second-font paragraph-font">{props.text}</div>
    </div>
  );
}

interface BulletTitleProps {
  title: string;
}

export function BulletTitle({ title }: BulletTitleProps) {
  return (
    <div className="bullet-title">
      <div className="bullet-point"></div>
      <h5 className="main-font">{title}</h5>
    </div>
  );
}
