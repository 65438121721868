import { Link } from 'react-router-dom';
import { CategoryType } from '../ProductPage/AllProductPage';
import { PRODUCT_BANNERS } from '../../ProductBannerInfo';
import { goContactItem } from '../Menu';

export default function Product() {
  const categories = Object.keys(PRODUCT_BANNERS) as CategoryType[];
  return (
    <section id="product" className="">
      <h1 className="spa-header main-font">PRODUCT</h1>
      {categories.map((c) => (
        <ProductBanner category={c} key={c}></ProductBanner>
      ))}
    </section>
  );
}

interface ProductBannerProps {
  category: CategoryType;
}

function ProductBanner(props: ProductBannerProps) {
  const { category } = props;
  const path = `url(/images/product/banner/${category}.png)`;
  return (
    <div className="product-banner">
      <div className="banner-image" style={{ backgroundImage: path }}></div>
      <div className="banner-container main-font">
        <h3 className="main-font">
          {PRODUCT_BANNERS[category].bannerHeader.toUpperCase()}
        </h3>
        {category === 'bakery-and-salad' ? (
          <>
            <p className="main-font paragraph-font">
              We have custom made packaging for the bakery and salad sector.
            </p>
            <p className="main-font paragraph-font">
              Speak with our team to discuss your need or any new idea.
            </p>
            <Link
              className="spa-btn"
              to={`/#contact`}
              onClick={(e) => goContactItem(e)}
            >
              CONTACT US
            </Link>
          </>
        ) : (
          <Link className="spa-btn" to={`/product/${category}`}>
            VIEW MORE
          </Link>
        )}
      </div>
    </div>
  );
}
