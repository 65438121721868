import React from 'react';
import { BulletTitle } from './About';

const solutionItems = [
  {
    title: 'Idea',
    text: 'Contact us and one of our SPA consultants will meet with you to discuss your packaging requirements, including Functionality/ Material/ Size/ Thickness/ Quantity/ Costs.',
  },
  {
    title: 'Drawing',
    text: 'SPA will take your concept and provide drawings to confirm accurate sizing.',
  },
  {
    title: 'Drawing Approval',
    text: 'Drawings will be sent to you by email for approval.',
  },
  {
    title: 'Pre Production Samples',
    text: 'Sample mould tooling and samples to be made based on drawing approval.',
  },
  {
    title: 'Sample Approval',
    text: 'Samples will be shipped to you for approval, in most cases allow 10 days from drawing approval.',
  },
  {
    title: 'Place Order',
    text: 'Official order placed with SPA based on approved samples.',
  },
  {
    title: 'Production Mould',
    text: 'Allow 2 -3 weeks for the full production mould to be made.',
  },
  {
    title: 'Full Production',
    text: 'From mould completion, allow approximately 4 - 5 weeks for delivery.',
  },
];

export default function Solution() {
  return (
    <section id="solution" className="">
      <h1 className="spa-header product-header main-font">
        PACKAGING SOLUTION
      </h1>
      {solutionItems.map((item, index) => {
        const textOnLeft = index % 2 === 0;
        const number = index + 1;
        const PROCESS_IMAGE = `/images/solution/0${number}.jpg`;
        return (
          <Process
            processNumber={`0${number}`}
            title={item.title}
            text={item.text}
            textOnLeft={textOnLeft}
            imgSrc={PROCESS_IMAGE}
            key={number}
          />
        );
      })}
    </section>
  );
}

interface ProcessProps {
  processNumber: string;
  title: string;
  text: string;
  textOnLeft: boolean;
  imgSrc: string;
}

function Process(props: ProcessProps) {
  const { processNumber, title, text, textOnLeft, imgSrc } = props;
  return (
    <div className="process">
      {textOnLeft ? (
        <>
          <ProcessText
            processNumber={processNumber}
            title={title}
            text={text}
            classStr="left"
          />
          <ProcessImg imgSrc={imgSrc} classStr="right"></ProcessImg>
        </>
      ) : (
        <>
          <ProcessImg imgSrc={imgSrc} classStr="left"></ProcessImg>
          <ProcessText
            processNumber={processNumber}
            title={title}
            text={text}
            classStr="right"
          />
        </>
      )}
    </div>
  );
}

interface ProcessTextProps {
  processNumber: string;
  title: string;
  text: string;
  classStr: string;
}

function ProcessText(props: ProcessTextProps) {
  return (
    <div className={`process-text process-item ${props.classStr}`}>
      <div>
        <div className="process-no second-font">{props.processNumber}</div>
        <BulletTitle title={props.title} />
        <p className="second-font paragraph-font">{props.text}</p>
      </div>
    </div>
  );
}

interface ProcessImgProps {
  imgSrc: string;
  classStr: string;
}

function ProcessImg(props: ProcessImgProps) {
  return (
    <div className={`process-img process-item ${props.classStr}`}>
      <div>
        <img src={props.imgSrc} alt="solution" />
      </div>
    </div>
  );
}
