import React, { useEffect, useState } from 'react';
import { goContactItem } from '../Menu';

export default function Home() {
  let setIntervalId: NodeJS.Timeout | undefined;
  const [currentSlideId, setCurrentSlideId] = useState(0);
  useEffect(() => {
    setIntervalId = setInterval(() => {
      nextSlide();
    }, 3000);
    return () => {
      if (setIntervalId) {
        clearInterval(setIntervalId);
      }
    };
  });
  const nextSlide = () => {
    let slides = document.querySelectorAll('.slide');
    slides[currentSlideId].classList.remove('current');
    let index = (currentSlideId + 1) % slides.length;
    slides[index].classList.add('current');
    setCurrentSlideId(index);
  };

  return (
    <section id="home" className="home">
      <div>
        <div
          className="slide current"
          style={{ backgroundImage: 'url(/images/home/1.jpg)' }}
        ></div>
        <div
          className="slide"
          style={{ backgroundImage: 'url(/images/home/2.jpg)' }}
        ></div>
        <div
          className="slide"
          style={{ backgroundImage: 'url(/images/home/3.jpg)' }}
        ></div>
        <div
          className="slide"
          style={{ backgroundImage: 'url(/images/home/4.jpg)' }}
        ></div>
      </div>
      <div className="header-container main-font">
        <div>
          <div className="slogan">PACKAGING THAT PERFORMS</div>
          <div className="separator-line"></div>
          <a
            href="#contact"
            className="btn spa-btn"
            onClick={(e) => goContactItem(e)}
          >
            Contact Us
          </a>
        </div>
      </div>
    </section>
  );
}
