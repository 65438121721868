import React from 'react';
import { createBrowserRouter, redirect } from 'react-router-dom';
import LandingPage from './Component/LandingPage/LandingPage';
import AllProductPage from './Component/ProductPage/AllProductPage';
import ProductItemPage from './Component/ProductPage/ProductItemPage';

export default createBrowserRouter([
  {
    path: '/',
    errorElement: <>Not Found</>,
    children: [
      {
        path: '',
        element: <LandingPage />,
      },
      {
        path: 'product/:category',
        element: <AllProductPage />,
      },
      {
        path: 'product/:category/:productCode',
        element: <ProductItemPage />,
      },
    ],
  },
]);
