import React from 'react';
import { Link } from 'react-router-dom';
import { goTo } from './LandingPage/LandingPage';

export const goHome = () => {
  let el = document.getElementById('home');
  scrollYTo(el);
};

export const goContactItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault();
  let el = document.getElementById('contact-item');
  scrollYTo(el);
};

export const scrollYTo = (el: HTMLElement | null) => {
  let y = el?.offsetTop ? el.offsetTop - 130 : 0; // 130 = 40 banner + 90 navbar
  window.scrollTo({
    top: y,
    behavior: 'smooth',
  });
};

export default function Menu() {
  return (
    <nav className="sticky">
      <div className="banner main-font">
        <div className="social-media">
          <a
            href="https://www.linkedin.com/company/specialty-packaging-aust-pty-ltd/"
            target="_blank"
          >
            <i className="fa-brands fa-linkedin"></i>
          </a>
        </div>
        <p>Customer Satisfaction Guaranteed</p>
        <div className="contact">
          07 3299 2035 /{' '}
          <a href="mailto:admin@specialtypackagingaust.com.au" target="_blank">
            <i className="fa-solid fa-envelope"></i>
          </a>
        </div>
      </div>
      <div className="navbar">
        <div className="spa-logo">
          <Link to={'/'}>
            <img className="logo" src="/images/logo.png" alt="SPA Logo" />
          </Link>
        </div>
        <ul className="menu">
          <MenuButton href="#home" text={'HOME'}></MenuButton>
          <DropdownMenu href="#about" text={'ABOUT US'}>
            <MenuButton href="#history" text={'HISTORY'} />
            <MenuButton href="#value" text={'OUR COMPANY VALUE'} />
            <MenuButton href="#capabilities" text={'OUR CAPABILITIES'} />
          </DropdownMenu>
          <MenuButton href="#product" text={'PRODUCT'}></MenuButton>
          <MenuButton href="#solution" text={'PACKAGING SOLUTION'}></MenuButton>
          <MenuButton href="#certificates" text={'CERTIFICATE'}></MenuButton>
          <MenuButton href="#contact" text={'CONTACT'}></MenuButton>
        </ul>
        <div className="apco-logo">
          <img
            className="logo"
            src="/images/APCO_Member_CMYK.png"
            alt="SPA Logo"
          />
        </div>
      </div>
    </nav>
  );
}

interface MenuButtonProps {
  href: string;
  text: string;
}

function MenuButton(props: MenuButtonProps) {
  const handleClick = (e: React.MouseEvent) => {
    const target = e.target as Node;
    if (target.nodeName === 'LI') {
      (target.firstChild as any).click();
    } else {
      goTo(props.href.replace('#', ''));
    }
  };

  return (
    <li onClick={handleClick}>
      <Link to={`/${props.href}`}>{props.text}</Link>
    </li>
  );
}

interface DropdownMenuProps {
  href: string;
  text: string;
  children?: React.ReactNode;
}

function DropdownMenu(props: DropdownMenuProps) {
  const { href, text, children } = props;
  return (
    <div className="dropdown">
      <MenuButton href={href} text={text}></MenuButton>
      <ul className="dropdown-content">{children}</ul>
    </div>
  );
}
