import React, { useEffect, useState } from 'react';
import Home from './Home';
import About from './About';
import Product from './Product';
import Solution from './Solution';
import Certificate from './Certificate';
import Contact from './Contact';
import { goHome, scrollYTo } from '../Menu';
import { SpaContainer } from '../SpaContainer';

export const goTo = (id?: string) => {
  const _id = id ?? window.location.hash.replace('#', '');
  let el = document.getElementById(_id);
  scrollYTo(el);
};

export default function LandingPage() {
  const [showScrollTopBtn, setShowScrollTopBtn] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    goTo();

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    let rootElement = document.documentElement;

    if (rootElement.scrollTop > 500) {
      setShowScrollTopBtn(true);
    } else {
      setShowScrollTopBtn(false);
    }
  };

  return (
    <SpaContainer>
      <Home />
      <About />
      <Product />
      <Solution />
      <Certificate />
      <Contact />
      {showScrollTopBtn ? (
        <button className="scroll-top-arrow" onClick={goHome}>
          <i className="fa-solid fa-arrow-up"></i>
        </button>
      ) : null}
    </SpaContainer>
  );
}
