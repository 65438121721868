import React from 'react';

export default function Footer() {
  return (
    <div id="footer" className="footer-font">
      <p>© 2023 - 2024 SpecialtyPackagingAust.</p>
      <p>All rights reserved</p>
    </div>
  );
}
