import React from "react";
import Footer from "./Footer";
import Menu from "./Menu";

interface SpaContainerProps {
  children?: React.ReactNode;
}
export function SpaContainer(props: SpaContainerProps) {
  return (
    <div className="App">
      <div>
        <Menu/>
        <main className="content">
          {props.children}
        </main>
        <Footer/>
      </div>
    </div>
  );
}