import { CategoryType } from './Component/ProductPage/AllProductPage';

export interface Product {
  productCode: string;
  description?: string;
  dimensions?: string;
  material?: string;
  subtype?: string;
}

export const PRODUCTS: { [key in CategoryType]: Product[] } = {
  'fresh-produce-clamshells': [
    {
      productCode: 'SPA-125BBP1',
      description: '125g Blueberry Punnet',
      dimensions: '129x110x40mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-125BBP3',
      description: '125g Blueberry Punnet',
      dimensions: '129X119X35mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-AP200',
      description: '200g auto pack',
      dimensions: '130x90x65mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-AP250',
      description: '250g auto pack',
      dimensions: '130x90x74.5mm ',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-AUT180',
      description: '180g auto pack for cherry tomato/ grape tomato',
      dimensions: '118x108x54mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-AUT200',
      description: '200g auto pack for cherry tomato/ grape tomato',
      dimensions: '118x108x65mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-AUT250',
      description: '250g auto pack for cherry tomato/ grape tomato',
      dimensions: '118x108x80mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-AUT250FB',
      description:
        '250g auto pack for cherry tomato/ grape tomato with flat base',
      dimensions: '118x108x80mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-1777',
      description: '175g capsicum pack',
      dimensions: '133x121x77mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-250flat',
      description: '250g hinged lid flat pack',
      dimensions: '175x118x50mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-300flat',
      description: '300g hinged lid flat pack',
      dimensions: '176X118X65mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-400LH',
      description: '400g clamshell with long hinge (open on the side)',
      dimensions: '175x110x75mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-400flat',
      description: '400g hinged lid flat pack',
      dimensions: '172x142x54mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-450flat',
      description: '450g hinged lid flat pack',
      dimensions: '182x140x55mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-500flat',
      description: '500g flat pack clamshell',
      dimensions: '175x135x72.3mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-600KFP',
      description: '600g hinged lid punnet for kiwi',
      dimensions: '185x96x100mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-800KFP',
      description: '800g hinged lid punnet for kiwi/grape',
      dimensions: '180x110x100mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-900KFP',
      description: '900g hinged lid punnet for kiwi/grape',
      dimensions: '180x113x104mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-900KFP LH',
      description:
        '900g hinged lid punnet for kiwi/grape with long hinge (open on the side)',
      dimensions: '185x120x105mm',
      material: 'Clear PET',
    },
    {
      productCode: 'MP-850',
      description: '850g clamshell bulk pack',
      dimensions: '172x172x90mm',
      material: 'Clear PET',
    },
    {
      productCode: 'MP-1000',
      description: '1kg clamshell bulk pack',
      dimensions: '182x177x100mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-1065',
      description: '1k hinged lid flat pack',
      dimensions: '273x175x65mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-1500',
      description: '1.5kg clamshell bulk pack',
      dimensions: '185x183x120mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-1550',
      description: '1.5kg clamshell bulk pack',
      dimensions: '273x175x98.7mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-1600ME2',
      description: '1.6kg clamshell bulk pack',
      dimensions: '270x180x100 mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-2KG',
      description: '2kg clamshell bulk pack',
      dimensions: '185x185x160mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-SAL77',
      description: 'Salad bowl 77mm deep',
      dimensions: '208x165x77mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-SC125',
      description: '125g sprout container',
      dimensions: '170x90x60mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-SC500',
      description: '500g sprout container',
      dimensions: '210x172x90mm',
      material: 'Clear PET',
    },
  ],
  'fresh-produce-trays': [
    {
      productCode: 'SPA-5x5x15 CL HR',
      description: '5x5x15 half rim rolled tray',
      dimensions: '125x125x15mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-7x5x15 CL HR',
      description: '7x5x15 half rim rolled tray',
      dimensions: '175x125x15mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-7x5x28 CL HR',
      description: '7x5x28 half rim rolled tray',
      dimensions: '190x144x28mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-8x5x15 CL HR',
      description: '8x5x15 half rim rolled tray',
      dimensions: '205x139x20mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-8x5x28 CL HR',
      description: '8x5x28 half rim rolled tray',
      dimensions: '139x214x28mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-8x7x20 CL HR',
      description: '8x7x20 half rim rolled tray',
      dimensions: '212x179x20mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-UTRAY1-35 CL',
      description: 'Universal Tray - Size #1 - 35 deep',
      dimensions: '235x175x 35mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-3CT',
      description: '3 cobs tray half rim rolled tray',
      dimensions: '142X166X15mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-4CT HR CL',
      description: '4 cobs half rim rolled tray',
      dimensions: '217.5x142x17mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-6CT AP',
      description: '6 cobs half rim rolled tray',
      dimensions: '270X173X15mm',
      material: 'Clear PET',
    },
    {
      productCode: '1KGOP-1',
      description: '1KG  open punnet -V1',
      dimensions: '188x138x101mm',
      material: 'Clear PET',
    },
    {
      productCode: '1KGOP-3',
      description: '1KG open punnet - V3',
      dimensions: '186x134x98mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-100SOP',
      description: '100g salad open tray',
      dimensions: '176x135x85mm',
      material: 'Clear PET',
    },
    {
      productCode: '1KGSL-L & 1KGSL-B',
      description: '1KG loose leaf salad container base and overcapping lid',
      dimensions: '390X292X155mm; 390X292X15mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-500FT',
      description: '500g flat tray',
      dimensions: '146x122x48mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-7540',
      description: '7x5x40 open tray',
      dimensions: '135x178x40mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-7557',
      description: '7x5x57 open tray',
      dimensions: '135x178x57mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-AVO2',
      description: '2 pack avocado tray',
      dimensions: '175x135x40mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-AVO3',
      description: '3 pack avocado tray',
      dimensions: '175x135x40mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-AVO4SML',
      description: '4 pack avocado tray small',
      dimensions: '182x136x48mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-AVO4LG',
      description: '4 pack avocado tray large',
      dimensions: '197x160x35mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-6PACKTRAY',
      description: '6 pack mango tray',
      dimensions: '238x175x50mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-ST250',
      description: '250g strawberries tub',
      dimensions: '170.4x130.4x56mm',
      material: ' Clear PET',
    },
    {
      productCode: 'SPA-STLID',
      description: 'Mathing lid for 250g/400g strawberries tub',
      dimensions: '176.5x136.5x10mm',
      material: ' Clear PET',
    },
  ],
  'meal-trays': [
    {
      productCode: 'SPA-500PPT',
      description: '500ml PP meal tray',
      dimensions: '185x136x35mm',
      material: 'Clear/Non carbon black PP',
    },
    {
      productCode: 'SPA-35PPT2C',
      description: '35mm high PP meal tray with  2 compartments',
      dimensions: '185x136x35mm',
      material: 'Clear/Non carbon black PP',
    },
    {
      productCode: 'SPA-600PPT2C',
      description: '600ml PP meal tray with 2 compartments',
      dimensions: '187x138x40mm',
      material: 'Clear/Non carbon black PP',
    },
    {
      productCode: 'SPA-700PPT',
      description: '700ml PP meal tray',
      dimensions: '185x136x42mm',
      material: 'Clear/Non carbon black PP',
    },
    {
      productCode: 'SPA-700PPT2C',
      description: '700ml PP meal tray with 2 compartments',
      dimensions: '185x136x47mm',
      material: 'Clear/Non carbon black PP',
    },
    {
      productCode: 'SPA-800PPT',
      description: '800ml PP meal tray',
      dimensions: '185x136x49.5mm',
      material: 'Clear/Non carbon black PP',
    },
    {
      productCode: 'SPA-1000PPT',
      description: '1000ml PP meal tray',
      dimensions: '185x136x65mm',
      material: 'Clear/Non carbon black PP',
    },
    {
      productCode: 'SPA-1.2KGPPT',
      description: '1kg PP meal tray',
      dimensions: '184x134.5x88mm',
      material: 'Clear/Non carbon black PP',
    },
    {
      productCode: 'SPA-MT LID',
      description: 'PET Overcapping id for PP meal tray range',
      dimensions: '190x141x9mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-PPTLID',
      description: 'PP Overcapping id for PP meal tray range',
      dimensions: '190X 140X 15.5 mm',
      material: 'Clear/Non carbon black PP',
    },
    {
      productCode: 'SPA-350SC',
      description: '350ml soup cup',
      dimensions: '120X52mm',
      material: 'Clear/Non carbon black PP',
    },
    {
      productCode: 'SPA-550PPRT',
      description: '550ml round PP meal tray',
      dimensions: '',
      material: 'Clear/Non carbon black PP',
    },
    {
      productCode: 'SPA-DSMT40',
      description: 'Diamond shape meal tray',
      dimensions: '213X146X40mm',
      material: 'Clear/Non carbon black PP',
    },
    {
      productCode: 'SPA-40PPT1C',
      description: '40mm deep PP meal tray',
      dimensions: '203x158x40mm',
      material: 'Clear/Non carbon black PP',
    },
    {
      productCode: 'SPA-500PETT',
      description: '500ml PET tray',
      dimensions: '185x136x35mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-700PETT',
      description: '700ml PET tray',
      dimensions: '185x136x42mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-800PETT',
      description: '800ml PET tray',
      dimensions: '185x136x49.5mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-1000PETT',
      description: '1000ml PET tray',
      dimensions: '185x136x65mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-PETT LID',
      description: 'PET Overcapping id for PET tray range',
      dimensions: '190x141mm',
      material: 'Clear PET',
    },
  ],
  'open-cell-and-meat-trays': [
    {
      productCode: 'OCN7534 RR',
      description: 'New Rim Rolled 7x5 OPEN CELL MEAT TRAY',
      dimensions: '182x139x34mm',
      material: 'Cear PET',
    },
    {
      productCode: 'OCN8534 RR',
      description: 'New Rim Rolled 8x5 OPEN CELL MEAT TRAY',
      dimensions: '208x137x34mm',
      material: 'Cear PET',
    },
    {
      productCode: 'OCN8734 RR',
      description: 'New Rim Rolled 8x7 OPEN CELL MEAT TRAY',
      dimensions: '216x185x34mm',
      material: 'Cear PET',
    },
    {
      productCode: 'OCN11534V2 RR',
      description: 'New Rim Rolled  11x5 V2 OPEN CELL MEAT TRAY',
      dimensions: '272x137x34mm',
      material: 'Cear PET',
    },
    {
      productCode: 'OCN11934 RR',
      description: 'New Rim Rolled 11x9 OPEN CELL MEAT TRAY',
      dimensions: '272x219x34mm',
      material: 'Cear PET',
    },
    {
      productCode: 'OCN131034 RR',
      description: 'New Rim Rolled 13x10 OPEN CELL MEAT TRAY',
      dimensions: '330x260x34mm',
      material: 'Cear PET',
    },
    {
      productCode: 'MAP11760',
      description: 'MAP TRAY  11x7x60',
      dimensions: '',
      material: 'Clear PP + EVA/EVOH/PE Laminate Film',
      subtype: 'Map',
    },
    {
      productCode: 'MAP11960',
      description: 'MAP TRAY  11x9x60',
      dimensions: '',
      material: 'Clear PP + EVA/EVOH/PE Laminate Film',
      subtype: 'Map',
    },
    {
      productCode: 'MAP11980',
      description: 'MAP TRAY  11x9x80',
      dimensions: '',
      material: 'Clear PP + EVA/EVOH/PE Laminate Film',
      subtype: 'Map',
    },
    {
      productCode: 'SPA-MAP9x7x40',
      description: 'MAP TRAY  9x7x40',
      dimensions: '220x167x40mm',
      material: 'Clear PP + EVA/EVOH/PE Laminate Film',
      subtype: 'Map',
    },
  ],
  'cups-and-tubs': [
    {
      productCode: 'MP-10 PET',
      description: '10oz (285ml) Clear PET drinking Cup Rim Rolled',
      dimensions: '',
      material: 'Clear PET',
    },
    {
      productCode: 'MP-10 PP',
      description: '10oz (285ml) Clear PP Drinking Cup; Rim Rolled',
      dimensions: '',
      material: 'PP',
    },
    {
      productCode: 'MP-15 PET',
      description: '15 oz 425ml PET Drinking Cups Rim Rolled',
      dimensions: '',
      material: 'PET',
    },
    {
      productCode: 'MP-15 PP',
      description: '15oz 425mm Clear PP Drinking Cup; Rim rolled',
      dimensions: '',
      material: 'PP',
    },
    {
      productCode: 'SPA-DT35',
      description: 'Dessert tub 35 and matching overcapping lid',
      dimensions: '88x35mm; 89.6x9mm',
      material: 'Clear PET',
    },
    {
      productCode: 'SPA-DT50',
      description: 'Dessert tub 50  and matching overcapping lid',
      dimensions: '88x50mm; 89.6x9mm',
      material: 'Clear PET',
    },
  ],
  'bakery-and-salad': [],
};
